*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App{

  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.wrapper{
  height: 100%;

  /* overflow-y: hidden; */
  width: 100%;
}

.navbar{
  background-color: black;
  color: #fff;
  padding: 10px;
}
.working-area{
  display: flex;
  width: 100%;
  height: 100%;
  width: 100%;
}

.clause-added{
  background-color: green;
  width: 300px;
  margin-inline: auto;
  padding: 1rem;
  margin-top: 1.5rem;
  position: relative;
  border-radius: 4px;
  cursor: move;
}

.move{
  cursor: move;
}

.clause-explained--added{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-show{
  display: block;
  transition: all 0.5s cubic-bezier(1,0,1,0);
}

.content-hide{
  display: none;
  transition: all 0.5s cubic-bezier(0,1,0,1);
}

.accordion--btn{
  cursor: pointer;
}

.btn--close{
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

.agreement{
  height: 100%;
  width: 35%;
  background-color: #C2BDBD;
  padding-top: 2rem;
}

.clauses{
  display: flex;
  justify-content: flex-start;
  /* width: 34rem; */

}

.clause{
  color: 'blue';
  line-height: 1; 
  padding: 10px;
  background-color: red;
  margin-bottom: 10px;
  max-width: 100px;
  cursor: pointer;
  max-height: 100px;
}

.content{
  
}

.flex{
  display: flex;
}

.active-content{

}

.container {
  background: #f1f1f1;
  border: 1px solid rgba(0, 0, 0, 0.274);
  max-width: 100%;
}

.bloc-tabs {
  max-width: 100%;
}

.content-tabs {
  flex-grow: 1;
}
.tabs {
  padding: 10px;
  text-align: center;
  min-width: 140px;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs  {
  background: white;
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: rgb(88, 147, 241);
}

.grid{
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-areas: "content tabs";
}

button {
  border: none;
  
}

.content {
  background: white;
  height: 100%;
  display: none;
  width: 100%;
}
.content h2 {
  padding: 0px 0 0px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}

.agreement{
  position: relative;
}

.city-time{
  position: absolute;
  top: 20px;
  right: 20px;
}

.agreement-title{
  display: block;
  margin-inline: auto;
  margin-top: 3rem;
  width: 50%;
  text-align: center;
}

